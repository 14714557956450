import React from "react";

const experienceContent = [
  {
    year: "Nov 2022 - Dec 2023",
    position: "Software Developer",
    companyName: "PS Analytics. Co",
    details: `Developed backend infrastructure for data analytics website. 
    Engineered data crawler and aggregator pipeline.`,
  },
  {
    year: "Jan 2023 - May 2023/Jan 2024 - May 2024",
    position: "Object Oriented Design Tutor",
    companyName: "Northeastern University",
    details: `Facilitated learning for readable code. Provided detailed feedback on student submissions.`,
  },
  {
    year: "Sep 2020 - Mar 2022",
    position: "Hazardous Materials Specialist",
    companyName: "Republic of Korea Army",
    details: `Facilitated live communications between Korean and US military departments.
    Operated chemical reconnaissance equipment.`,
  },
];



const Experience = () => {
  return (
    <ul>
      {experienceContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.position}
            <span className="place open-sans-font">{val.compnayName}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Experience;
