import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import Modal from "react-modal";
import cancelImg from "../../assets/img/cancel.svg";
import indexData from "../../content/index.json";
import grayMatter from "gray-matter";
import { LazyLoadImage } from 'react-lazy-load-image-component';

Modal.setAppElement("#root");
window.Buffer = window.Buffer || require("buffer").Buffer;

const Blog = () => {
  const [markdownFiles, setMarkdownFiles] = useState([]);
  const [selectedPost, setSelectedPost] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    const fetchMetadata = async () => {
      const metadataArray = [];
      for (const filename of indexData.files) {
        try {
          const { default: url } = await import(`../../content/${filename}`);
          const response = await fetch(url);
          const text = await response.text();
          const { data } = grayMatter(text);
          metadataArray.push({ filename, metadata: data });
        } catch (error) {
          console.error(`Error fetching metadata for ${filename}:`, error);
        }
      }
      setMarkdownFiles(metadataArray);
    };

    fetchMetadata();
  }, []);

  const openModal = async (filename) => {
    try {
      const { default: url } = await import(`../../content/${filename}`);
      const response = await fetch(url);
      const text = await response.text();
      const { content, data } = grayMatter(text);
      setSelectedPost({ content, metadata: data });
      setModalIsOpen(true);
    } catch (error) {
      console.error("Error importing Markdown content:", error);
    }
  };

  const closeModal = () => {
    setSelectedPost(null);
    setModalIsOpen(false);
  };

  return (
    <div className="row">
      {markdownFiles.map((file, index) => (
        <div
          key={index}
          className="col-12 col-md-6 col-lg-6 col-xl-4 mb-30"
          onClick={() => openModal(file.filename)}
        >
          <article className="post-container">
            <div className="post-thumb">
              <div className="d-block position-relative overflow-hidden">
                <LazyLoadImage
                  src={file.metadata.image}
                  alt={file.metadata.title}
                  effect="blur"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="post-content">
              <h3>{file.metadata.title}</h3>
            </div>
          </article>
        </div>
      ))}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Blog Post"
        className="custom-modal dark"
        overlayClassName="custom-overlay dark"
        closeTimeoutMS={500}
      >
        <button className="close-modal" onClick={closeModal}>
          <img src={cancelImg} alt="close icon" />
        </button>
        <div className="box_inner blog-post">
          {selectedPost && (
            <>
              <h1>{selectedPost.metadata.title}</h1>
              <p>{selectedPost.metadata.date.toString()}</p>
              <ReactMarkdown>{selectedPost.content}</ReactMarkdown>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default Blog;
